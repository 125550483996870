@font-face {
  font-family: 'Inter-Regular', sans-serif;
  src: local('Inter'), url(./fonts/Inter-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter-Bold', sans-serif;
  src: local('Inter'), url(./fonts/Inter-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter-SemiBold', sans-serif;
  src: local('Inter'), url(./fonts/Inter-SemiBold.ttf) format('truetype');
}


@font-face {
  font-family: 'Inter-Medium', sans-serif;
  src: local('Inter'), url(./fonts/Inter-Medium.ttf) format('truetype');
}

